<template>
    <styled-table
        :headers="headers"
        :items="items"
        :rows-per-page="50"
        min-width="1000px"
        fixed-totals
        has-search>
        <template #items="{ item, index }">
            <tr
                :class="[
                    (index % 2) ? 'row-even' : 'row-odd'
                ]">
                <td>
                    {{ item.dealer_id }}
                </td>
                <td>
                    <div
                        v-if="item.running.value"
                        class="dealer-name">
                        <styled-tooltip
                            nudge-top="5"
                            position="top">
                            <template #content>
                                <div v-if="item.running.status === 'running'">
                                    Pinterest stats are currently being populated for this date range.
                                    Please check back soon to see your data!
                                </div>
                                <div v-else>
                                    Pinterest stats processing failed. Sometimes this can be resolved by clicking retry.
                                    If not, click the info icon for additional steps to resolve the issue.
                                </div>
                            </template>
                            <div class="dealer-holder">
                                <icon
                                    v-if="item.running.status === 'running'"
                                    class="mr-2"
                                    size="15"
                                    :color="colors.yellow"
                                    name="indicator-warning" />
                                <icon
                                    v-else
                                    class="mr-2"
                                    size="15"
                                    :color="colors.red"
                                    name="indicator-error" />
                                <span>{{ item.dealer_name }}</span>
                            </div>
                            <div
                                class="dealer-processing"
                                :class="{'failed': item.running.status === 'failed'}">
                                <v-progress-circular
                                    v-if="item.running.status === 'running'"
                                    class="mr-2"
                                    size="15"
                                    width="2"
                                    color="primary"
                                    indeterminate />
                                <span v-if="item.running.status === 'running'">Stats processing...</span>
                                <span v-else>Stats failed</span>
                                <div
                                    v-if="item.running.status === 'failed'"
                                    class="info-failed">
                                    <a
                                        class="ml-2"
                                        @click.prevent="$emit('make-sync', 'pinterest', item.dealer_id)">retry</a>
                                    <span @click="checkConnection(item.dealer_id)">
                                        <icon
                                            class="ml-2 info-icon"
                                            size="15"
                                            name="indicator-info" />
                                    </span>
                                </div>
                            </div>
                        </styled-tooltip>
                    </div>
                    <div v-else>
                        {{ item.dealer_name }}
                    </div>
                </td>
                <td>
                    {{ item.ad_account_id }}
                </td>
                <table-cell
                    :is-null="!getItemValue(item, 'spend')"
                    class="text-xs-center">
                    {{ getItemValueFormatted(item, 'spend') }}
                </table-cell>
                <table-cell
                    :is-null="!getItemValue(item, 'impressions')"
                    class="text-xs-center">
                    {{ getItemValueFormatted(item, 'impressions') }}
                </table-cell>
                <table-cell
                    :is-null="!getItemValue(item, 'cpm')"
                    class="text-xs-center">
                    {{ getItemValueFormatted(item, 'cpm') }}
                </table-cell>
                <table-cell
                    :is-null="!getItemValue(item, 'total_clickthrough')"
                    class="text-xs-center">
                    {{ getItemValueFormatted(item, 'total_clickthrough') }}
                </table-cell>
                <table-cell
                    :is-null="!getItemValue(item, 'total_clickthrough_rate')"
                    class="text-xs-center">
                    {{ getItemValueFormatted(item, 'total_clickthrough_rate') }}
                </table-cell>
                <table-cell
                    :is-null="!getItemValue(item, 'total_clickthrough_cost_per')"
                    class="text-xs-center">
                    {{ getItemValueFormatted(item, 'total_clickthrough_cost_per') }}
                </table-cell>
                <table-cell
                    :is-null="!getItemValue(item, 'total_lead')"
                    class="text-xs-center">
                    {{ getItemValueFormatted(item, 'total_lead') }}
                </table-cell>
                <table-cell
                    :is-null="!getItemValue(item, 'total_lead_rate')"
                    class="text-xs-center">
                    {{ getItemValueFormatted(item, 'total_lead_rate') }}
                </table-cell>
                <table-cell
                    :is-null="!getItemValue(item, 'total_lead_cost_per')"
                    class="text-xs-center">
                    {{ getItemValueFormatted(item, 'total_lead_cost_per') }}
                </table-cell>
                <table-cell
                    :is-null="!getItemValue(item, 'total_offline_checkout')"
                    class="text-xs-center">
                    {{ getItemValueFormatted(item, 'total_offline_checkout') }}
                </table-cell>
                <table-cell
                    :is-null="!getItemValue(item, 'cps')"
                    class="text-xs-center">
                    {{ getItemValueFormatted(item, 'cps') }}
                </table-cell>
            </tr>
        </template>
    </styled-table>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledTable from '@/components/globals/StyledTable';
import TableCell from '@/components/globals/TableCell';
import colors from '@/helpers/colors';
import { SET_CHANNEL_CONNECTION_DEBUG_PANEL } from '@/store/mutation-types';

export default {
    components: {
        Icon,
        StyledTooltip,
        StyledTable,
        TableCell
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            colors,
            headers: [
                {
                  text: 'Dealer ID',
                  id: 'id',
                  value: 'dealer_id',
                  align: 'left',
                  width: '8%',
                  sortable: true,
                  cellAlign: 'left',
                },
                {
                    text: 'Dealer Name',
                    id: 'name',
                    value: 'dealer_name',
                    align: 'left',
                    width: '15%',
                    sortable: true,
                    cellAlign: 'left',
                },
                {
                    text: 'Ad Account Id',
                    id: 'ad_account_id',
                    value: 'ad_account_id',
                    align: 'left',
                    width: '10%',
                    sortable: true,
                    cellAlign: 'left',
                },
                {
                    text: 'Spend',
                    id: 'spend',
                    value: 'metrics.spend.value',
                    align: 'left',
                    width: '6%',
                    sortable: true,
                    tooltip: this.$t('metrics.spend'),
                    total: 'sum',
                    average: true,
                    format: 'currency',
                    cellAlign: 'center'
                },
                {
                    text: 'Impr.',
                    id: 'impressions',
                    value: 'metrics.impressions.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.impressions'),
                    total: 'sum',
                    average: true,
                    format: 'number',
                    cellAlign: 'center'
                },
                {
                    text: 'CPM',
                    id: 'cpm',
                    value: 'metrics.cpm.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.cpm'),
                    total: (totals) => {
                        return totals.spend / ( totals.impressions / 1000 );
                    },
                    format: 'currency',
                    cellAlign: 'center'
                },
                {
                    text: 'Clicks',
                    id: 'total_clickthrough',
                    value: 'metrics.total_clickthrough.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.clicks'),
                    total: 'sum',
                    average: true,
                    format: 'number',
                    cellAlign: 'center'
                },
                {
                    text: 'CTR',
                    id: 'total_clickthrough_rate',
                    value: 'metrics.total_clickthrough_rate.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.ctr'),
                    total: (totals) => {
                        return totals.total_clickthrough / totals.impressions;
                    },
                    format: 'percent',
                    cellAlign: 'center'
                },
                {
                    text: 'CPC',
                    id: 'total_clickthrough_cost_per',
                    value: 'metrics.total_clickthrough_cost_per.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.cpc'),
                    total: (totals) => {
                        return totals.spend / totals.total_clickthrough;
                    },
                    format: 'currency',
                    cellAlign: 'center'
                },
                {
                    text: 'Leads',
                    id: 'total_lead',
                    value: 'metrics.total_lead.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.web_leads'),
                    total: 'sum',
                    average: true,
                    format: 'number',
                    cellAlign: 'center'
                },
                {
                    text: 'CR',
                    id: 'total_lead_rate',
                    value: 'metrics.total_lead_rate.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.lead_rate'),
                    total: (totals) => {
                        return totals.total_lead / totals.clicks;
                    },
                    format: 'percent',
                    cellAlign: 'center'
                },
                {
                    text: 'CPL',
                    id: 'total_lead_cost_per',
                    value: 'metrics.total_lead_cost_per.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.cpl'),
                    total: (totals) => {
                        return totals.spend / totals.total_lead;
                    },
                    format: 'currency',
                    cellAlign: 'center'
                },
                {
                    text: 'Sales',
                    id: 'sales',
                    value: 'metrics.total_offline_checkout.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.sales'),
                    total: 'sum',
                    average: true,
                    format: 'number',
                    cellAlign: 'center'
                },
                {
                    text: 'CPS',
                    id: 'cps',
                    value: 'metrics.cps.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.cps'),
                    total: (totals) => {
                        return totals.spend / totals.sales;
                    },
                    format: 'currency',
                    cellAlign: 'center'
                },
            ]
        };
    },
    methods: {
        checkConnection(dealerId) {
            this.$store.commit(SET_CHANNEL_CONNECTION_DEBUG_PANEL, { status: true, channel: 'pinterest', dealer_id: dealerId });
        },
        getItemValue(item, name) {
            const value = item.metrics ? item.metrics[name].value : null;
            return value;
        },
        getItemValueFormatted(item, name) {
            const formattedValue = item.metrics ? item.metrics[name].formatted : null;
            return formattedValue;
        },
    }
};
</script>
<style lang="scss" scoped>
.dealer-name {
    position: relative;
}
.dealer-holder {
    display: flex;
    align-items: center;
}
.dealer-processing {
    display: flex;
    align-items: center;
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -13px;
    white-space: nowrap;
    font-size: 10px;
    color: $gray;
    background: $gray-lightshade;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 9;
}
.dealer-processing.failed {
    background: $light-error;
}
.info-icon {
    cursor: pointer;
}
.info-failed {
    display: flex;
    align-items: center;
}
</style>
