<template>
    <styled-table
        :headers="headers"
        :items="items"
        :rows-per-page="50"
        min-width="1000px"
        fixed-totals
        has-search>
        <template #items="{ item, index }">
            <tr
                :class="[
                    (index % 2) ? 'row-even' : 'row-odd'
                ]">
                <td>
                    {{ item.dealer_id }}
                </td>
                <td>
                    {{ item.dealer_name }}
                </td>
                <td>
                    {{ item.ad_account_id }}
                </td>
                <table-cell
                    :is-null="!item.metrics.spend.value"
                    class="text-xs-center">
                    {{ item.metrics.spend.formatted }}
                </table-cell>
                <table-cell
                    :is-null="!item.metrics.impressions.value"
                    class="text-xs-center">
                    {{ item.metrics.impressions.formatted }}
                </table-cell>
                <table-cell
                    :is-null="!item.metrics.cpm.value"
                    class="text-xs-center">
                    {{ item.metrics.cpm.formatted }}
                </table-cell>
                <table-cell
                    :is-null="!item.metrics.swipes.value"
                    class="text-xs-center">
                    {{ item.metrics.swipes.formatted }}
                </table-cell>
                <table-cell
                    :is-null="!item.metrics.swipes_rate.value"
                    class="text-xs-center">
                    {{ item.metrics.swipes_rate.formatted }}
                </table-cell>
                <table-cell
                    :is-null="!item.metrics.swipes_cost_per.value"
                    class="text-xs-center">
                    {{ item.metrics.swipes_cost_per.formatted }}
                </table-cell>
                <table-cell
                    :is-null="!item.metrics.conversion_sign_ups.value"
                    class="text-xs-center">
                    {{ item.metrics.conversion_sign_ups.formatted }}
                </table-cell>
                <table-cell
                    :is-null="!item.metrics.conversion_sign_ups_rate.value"
                    class="text-xs-center">
                    {{ item.metrics.conversion_sign_ups_rate.formatted }}
                </table-cell>
                <table-cell
                    :is-null="!item.metrics.conversion_sign_ups_cost_per.value"
                    class="text-xs-center">
                    {{ item.metrics.conversion_sign_ups_cost_per.formatted }}
                </table-cell>
                <table-cell
                    :is-null="!item.metrics.conversion_purchases.value"
                    class="text-xs-center">
                    {{ item.metrics.conversion_purchases.formatted }}
                </table-cell>
                <table-cell
                    :is-null="!item.metrics.cps.value"
                    class="text-xs-center">
                    {{ item.metrics.cps.formatted }}
                </table-cell>
            </tr>
        </template>
    </styled-table>
</template>

<script>
import StyledTable from '@/components/globals/StyledTable';
import TableCell from '@/components/globals/TableCell';

export default {
    components: {
        StyledTable,
        TableCell
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            headers: [
                {
                  text: 'Dealer ID',
                  id: 'id',
                  value: 'dealer_id',
                  align: 'left',
                  width: '5%',
                  sortable: true,
                  cellAlign: 'left',
                },
                {
                    text: 'Dealer Name',
                    id: 'name',
                    value: 'dealer_name',
                    align: 'left',
                    width: '15%',
                    sortable: true,
                    cellAlign: 'left',
                },
                {
                    text: 'Ad Account Id',
                    id: 'ad_account_id',
                    value: 'ad_account_id',
                    align: 'left',
                    width: '20%',
                    sortable: true,
                    cellAlign: 'left',
                },
                {
                    text: 'Spend',
                    id: 'spend',
                    value: 'metrics.spend.value',
                    align: 'left',
                    width: '6%',
                    sortable: true,
                    tooltip: this.$t('metrics.spend'),
                    total: 'sum',
                    average: true,
                    format: 'currency',
                    cellAlign: 'center'
                },
                {
                    text: 'Impr.',
                    id: 'impressions',
                    value: 'metrics.impressions.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.impressions'),
                    total: 'sum',
                    average: true,
                    format: 'number',
                    cellAlign: 'center'
                },
                {
                    text: 'CPM',
                    id: 'cpm',
                    value: 'metrics.cpm.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.cpm'),
                    total: (totals) => {
                        return totals.spend / ( totals.impressions / 1000 );
                    },
                    format: 'currency',
                    cellAlign: 'center'
                },
                {
                    text: 'SU',
                    id: 'swipes',
                    value: 'metrics.swipes.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.swipe_ups'),
                    total: 'sum',
                    average: true,
                    format: 'number',
                    cellAlign: 'center'
                },
                {
                    text: 'SUR',
                    id: 'swipes_rate',
                    value: 'metrics.swipes_rate.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.swipe_up_rate'),
                    total: (totals) => {
                        return totals.swipes / totals.impressions;
                    },
                    format: 'percent',
                    cellAlign: 'center'
                },
                {
                    text: 'CPSU',
                    id: 'swipes_cost_per',
                    value: 'metrics.swipes_cost_per.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.cpsu'),
                    total: (totals) => {
                        return totals.spend / totals.swipes;
                    },
                    format: 'currency',
                    cellAlign: 'center'
                },
                {
                    text: 'Leads',
                    id: 'conversion_sign_ups',
                    value: 'metrics.conversion_sign_ups.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.web_leads'),
                    total: 'sum',
                    average: true,
                    format: 'number',
                    cellAlign: 'center'
                },
                {
                    text: 'CR',
                    id: 'conversion_sign_ups_rate',
                    value: 'metrics.conversion_sign_ups_rate.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.lead_rate'),
                    total: (totals) => {
                        return totals.conversion_sign_ups / totals.swipes;
                    },
                    format: 'percent',
                    cellAlign: 'center'
                },
                {
                    text: 'CPL',
                    id: 'conversion_sign_ups_cost_per',
                    value: 'metrics.conversion_sign_ups_cost_per.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.cpl'),
                    total: 'sum',
                    average: true,
                    format: 'currency',
                    cellAlign: 'center'
                },
                {
                    text: 'Sales',
                    id: 'sales',
                    value: 'metrics.conversion_purchases.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.sales'),
                    total: 'sum',
                    average: true,
                    format: 'number',
                    cellAlign: 'center'
                },
                {
                    text: 'CPS',
                    id: 'cps',
                    value: 'metrics.cps.value',
                    align: 'left',
                    width: '4%',
                    sortable: true,
                    tooltip: this.$t('metrics.cps'),
                    total: (totals) => {
                        return totals.spend / totals.sales;
                    },
                    format: 'currency',
                    cellAlign: 'center'
                },
            ]
        };
    }
};
</script>
